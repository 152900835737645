import m from "mithril";

/**
 * Traffic route
 */
const Traffic =
{
    view: () =>
    (
        <div class="bg-blue-500">
            <header class="flex">
                <a onclick={ Traffic.onsearchclicked } class="flex items-center text-white cursor-pointer m-8 focus:outline-none">
                    <i class="fa fa-search fa-lg mr-3"></i>
                    <span class="text-sm">Try a search!</span>
                </a>
            </header>
            <section class="flex flex-col items-center">
                <h1 class="text-4xl text-gray-100 font-bold">Wuffee Search Traffic</h1>
                <div class="mt-10 mr-10" id="graphdiv"></div>
                <div class="flex justify-center my-12 w-1/2">
                    <div class="flex flex-col text-xs w-1/2">
                        <div class="flex justify-between border-b border-gray-200 p-2">
                            <span class="text-white font-bold">1,000 Searches</span>
                            <span class="text-gray-100">2018.01.20</span>
                        </div>
                        <div class="flex justify-between border-b border-gray-200 p-2">
                            <span class="text-white font-bold">10,000 Searches</span>
                            <span class="text-gray-100">2018.03.12</span>
                        </div>
                        <div class="flex justify-between p-2">
                            <span class="text-white font-bold">100,000 Searches</span>
                            <span class="text-gray-100">2019.06.28</span>
                        </div>
                    </div>
                    <div class="flex flex-col text-xs w-5/12 ml-5 ">
                        <div class="flex justify-between border-b border-gray-200 p-2">
                            <span class="text-white font-bold">250,000 Searches</span>
                            <span class="text-gray-100">2019.12.26</span>
                        </div>
                        <div class="flex justify-between border-b border-gray-200 p-2">
                            <span class="text-white font-bold">500,000 Searches</span>
                            <span class="text-gray-100">2020.01.02</span>
                        </div>
                        <div class="flex justify-between p-2">
                            <span class="text-white font-bold">1,000,000 Searches</span>
                            <span class="text-gray-100">2020.02.21</span>
                        </div>
                    </div>
                </div>
            </section>
            <div class="bg-gray-200 py-3">
                <section class="flex justify-center bg-white py-10">
                    <div class="flex justify-between w-5/12">
                        <div class="flex flex-col">
                            <span class="text-gray-600 tracking-wider text-xs uppercase">All Time Searches</span>
                            <span class="text-gray-800 tracking-tight text-3xl font-bold">
                                1,716,572
                            </span>
                        </div>
                        <div class="flex flex-col">
                            <span class="text-gray-600 tracking-wider text-xs uppercase">Weekly Record</span>
                            <span class="text-green-600 tracking-tight text-3xl font-bold">
                                20,840
                            </span>
                        </div>
                    </div>
                </section>
                <section class="flex flex-col justify-center items-center bg-white my-3 py-10">
                    <div class="flex justify-between w-5/12">
                        <div class="flex flex-col">
                            <span class="text-gray-800 tracking-tight text-3xl font-bold">
                                2020 Traffic
                            </span>
                            <span class="text-gray-700 tracking-wide text-xs uppercase">Wuffee Search History</span>
                        </div>
                        <div class="flex flex-col text-right">
                            <span class="text-gray-800 tracking-tight text-3xl font-bold">
                                1,263,351
                            </span>
                            <span class="text-gray-700 tracking-wide text-xs uppercase">Yearly Searches</span>
                        </div>
                    </div>
                </section>
                <section class="flex flex-col justify-center items-center bg-white my-3 py-10">
                    <div class="flex justify-between w-5/12">
                        <div class="flex flex-col">
                            <span class="text-gray-800 tracking-tight text-3xl font-bold">
                                2019 Traffic
                            </span>
                            <span class="text-gray-700 tracking-wide text-xs uppercase">Wuffee Search History</span>
                        </div>
                        <div class="flex flex-col text-right">
                            <span class="text-gray-800 tracking-tight text-3xl font-bold">
                                486,573
                            </span>
                            <span class="text-gray-700 tracking-wide text-xs uppercase">Yearly Searches</span>
                        </div>
                    </div>
                </section>
                <section class="flex flex-col justify-center items-center bg-white my-3 py-10">
                    <div class="flex justify-between w-5/12">
                        <div class="flex flex-col">
                            <span class="text-gray-800 tracking-tight text-3xl font-bold">
                                2018 Traffic
                            </span>
                            <span class="text-gray-700 tracking-wide text-xs uppercase">Wuffee Search History</span>
                        </div>
                        <div class="flex flex-col text-right">
                            <span class="text-gray-800 tracking-tight text-3xl font-bold">
                                50,408
                            </span>
                            <span class="text-gray-700 tracking-wide text-xs uppercase">Yearly Searches</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    ),
    oncreate: () => {

        var graph = document.getElementById("graphdiv");

        var g = new Dygraph(graph, "https://cors-anywhere.herokuapp.com/https://duckduckgo.com/traffic_data/direct.csv",
            {
                height: 380,
                width: Math.min(window.innerWidth, 760),
                rollPeriod: 100,
                showRoller: true,
                title: "Searches in Thousands – (2018-20)",
                strokeWidth: 4,
                color: 'white',
                gridLineColor: "#4299e1",
                axisLineColor: "#4299e1",
                axisLabelFontSize: 12,
                highlightCircleSize: 5,
                xRangePad: 20,
                yRangePad: 10,
                axes: {
                    y: {
                        labelsKMB: true,
                        axisLabelWidth: 50,
                        valueFormatter: function (v) {
                            return Math.round(v);
                        }
                    },
                    x: {
                        drawGrid: false,
                    }
                },
                underlayCallback: function(canvas, area, g) {
                    canvas.fillStyle = "rgba(0, 0, 0, 0.1)";
                    canvas.fillRect(area.x, area.y, area.w, area.h);
                }
            }
          );
    },
    onsearchclicked: () => {
        m.route.set("/");
    }
}

export default Traffic;
