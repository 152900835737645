import m from "mithril";

/**
 * link item
 */
const Link =
{
    view: (vnode) =>
    (
        <a class="result flex flex-col rounded cursor-pointer p-2" href={ vnode.attrs.link }>
            <h2 class="hover:underline" style="color: #00278e;">{ vnode.attrs.title }</h2>
            <a style="font-size: 0.83em; color: #20692b; margin-top: 1px; margin-bottom: 2px;">{ vnode.attrs.visibleLink }</a>
            <p class="text-sm tracking-wide" style="font-size: 0.83em; color: #494949;">{ vnode.attrs.snippet }</p>
        </a>
    )
}

export default Link;
