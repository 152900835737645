import m from "mithril";

import Link from "./link";

/**
 * search route
 */
const Search = () => {
    
    var results = [];
    
    return {
        view: () =>
        (
            <div class="search bg-white">
                <div class="flex flex-col items-center absolute fixed bg-white rounded right-0 m-5 mt-16 px-5 py-4" style="border: 1px solid rgba(0,0,0,0.15); box-shadow: 0 4px 10px rgba(0,0,0,0.1);">
                    <img src="https://duckduckgo.com/assets/onboarding/robot-icon-frameless.svg" class="-mt-20" style="height: 9rem;" />
                    <h1 class="font-semibold text-center" style="line-height: 1.35; font-size: 17px; color: #333;">Your data shouldn't be for sale. <br></br>At Wuffee, we agree.</h1>
                    <ol class="text-sm text-gray-700 list-decimal leading-loose mt-1 ml-3">
                        <li>Block advertising trackers.</li>
                        <li>Keep your search history private.</li>
                        <li>Take control of your personal data.</li>
                    </ol>
                    <button class="bg-blue-400 hover:bg-blue-500 text-xs uppercase tracking-wide font-bold text-white rounded w-full mb px-6 py-2 shadow-sm hover:shadow-lg mt-2 transition duration-200 focus:outline-none">
                        <a href="https://help.wuffee.com">
                            Add Wuffee to Chrome
                        </a>
                    </button>
                </div>
                <header class="flex" style="height: 6.3rem; background: #fafafa; box-shadow: 0 1px 0 rgba(0,0,0,0.15);">
                    <div class="flex flex-col" style="margin-left: 155px;">
                        <input class="rounded hover:shadow-md px-3 mt-4 focus:shadow-md focus:outline-none" style="width: 37rem; height: 43px; color: #222; font-size: 15px; border: 1px solid rgba(0,0,0,0.15); box-shadow: 0 2px 3px rgba(0,0,0,0.06);" value={ m.route.param("q") } id="search" />
                        <div class="flex flex-grow ml-2">
                            <a href="/" class="flex tracking-wide items-center border-b-2 border-gray-800 mr-4" style="font-size: 0.8rem; margin-bottom: -1px;">
                                All
                            </a>
                            <a onclick="alert('Comming Soon')" class="flex tracking-wide items-center border-b mr-4 hover:text-gray-800" style="font-size: 0.8rem; color: #6e6e6e; margin-bottom: -1px; border-color: transparent;">
                                Images
                            </a>
                            <a href="https://wuffee.com/stocks" class="flex tracking-wide items-center border-b cursor-pointer hover:text-gray-800" style="font-size: 0.8rem; color: #6e6e6e; margin-bottom: -1px; border-color: transparent;">
                                Stocks
                            </a>
                            <a href="/" class="flex tracking-wide items-center border-b hover:text-gray-800 mr-3 ml-auto" style="font-size: 0.8rem; color: #6e6e6e; margin-bottom: -1px; border-color: transparent;">
                                <a href="https://help.wuffee.com">Settings</a>
                                <i class="fa fa-caret-down ml-1"></i>
                            </a>
                        </div>
                    </div>
                    <div class="flex flex-col ml-auto">
                        <div class="flex items-center text-sm mr-8" style="margin-top: 2rem; color: #aaaaaa;">
                            <button class="flex items-center mr-6 hover:text-gray-600 focus:outline-none">
                                <i class="fa fa-bullhorn text-xl"></i>
                                <i class="fa fa-caret-down ml-2"></i>
                            </button>
                            <button class="flex items-center hover:text-gray-600 focus:outline-none">
                                <i class="fa fa-bars text-lg"></i>
                            </button>
                        </div>
                    </div>
                </header>
                <div class="flex">
                    <div class="mt-3" style="width: 37rem; margin-left: 155px;">
                        <a href="https://www.redcross.org/donate/donation.html/" class="result flex flex-col rounded cursor-pointer p-2">
                            <h2 class="flex hover:underline" style="color: #00278e;">Donate Now | American Red Cross <span class="flex items-center rounded text-xs ml-2 h-6 ml-auto px-2">DONATE</span></h2>
                            <a href="https://www.redcross.org/donate/donation.html/" style="font-size: 0.83em; color: #20692b; margin-top: 1px; margin-bottom: 2px;">https://www.redcross.org/donate/donation.html/</a>
                            <p class="text-sm tracking-wide" style="font-size: 0.83em; color: #494949;">My Donation · Tell Alexa: “Alexa, make a donation to the American Red Cross.” · Your donation is processed through Amazon Pay using the information already ...</p>
                        </a>
                        <hr class="my-2"></hr>
                        {
                            results.map((result, i) => (
                                <Link
                                    title={ result.title }
                                    link={ result.link }
                                    snippet={ result.snippet }
                                    visibleLink={ result['visible_link'] }
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        ),
        oncreate: () => {

            document.body.style.backgroundColor = "#ffffff";

            m.request({
                method: "GET",
                url: "https://api.wuffee.com/v1/search",
                params: { q: m.route.param('q') }
            })
            .then(function(result) {
                console.log(result);
                results = result;
            })
    
            document.getElementsByTagName("input")[0].addEventListener("keydown", function(event) {
                if (event.key == "Enter" && event.target.value.length > 0) {
                    window.document.location = "/search?q=" + encodeURI(event.target.value)
                }
            })
        },
        onStocksClicked: () => {
            m.route.set("/stocks")
        }   
    }
}

export default Search;
