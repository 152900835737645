import m from "mithril"

import Home from "./routes/home"
import Search from "./routes/search"
import Stocks from "./routes/stocks"
import Traffic from "./routes/traffic"

const root = document.body

m.route.prefix = '#'

// then, render
m.route(root, "/",
{
    "/": Home,
    "/search": Search, 
    "/stocks": Stocks, 
    "/traffic": Traffic 
})