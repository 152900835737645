import m from "mithril";

/**
 * stocks route
 */
const Stocks =
{
    view: () =>
    (
        <div class="tradingview-widget-container">
            <div id="analytics-platform"></div>
            <script type="text/javascript" src="https://s3.tradingview.com/tv.js" onload={ Stocks.createChart }></script>
        </div>
    ),
    createChart: () => {
        new TradingView.widget(
            {
                "container_id": "analytics-platform",
                "width": window.innerWidth,
                "height": window.innerHeight,
                "symbol": "NASDAQ:AAPL",
                "interval": "D",
                "timezone": "exchange",
                "theme": "light",
                "style": "3",
                "toolbar_bg": "#f1f3f6",
                "withdateranges": true,
                "allow_symbol_change": true,
                "save_image": false,
                "details": true,
                "hotlist": true,
                "calendar": true,
                "locale": "en"
            }
        );
    }
}

export default Stocks;
