import m from "mithril";

/**
 * home route
 */
const Home =
{
    view: () =>
    (
        <div class="home" style="height: 100vh; background: rgba(0, 0, 0, 0.2);">
            <div class="background"/>
            <header class="flex justify-end p-5">
                <button class="border-2 border-gray-200 rounded-full shadow-sm hover:shadow-lg w-10 h-10 transition duration-200 focus:outline-none mr-3">
                    <i class="text-gray-100 fa fa-bell"></i>
                </button>
                <button class="border-2 border-gray-200 rounded-full shadow-sm hover:shadow-lg w-10 h-10 transition duration-200 focus:outline-none">
                    <i class="text-gray-100 fa fa-bars"></i>
                </button>
            </header>
            <section class="hero flex flex-col justify-center items-center mt-32">
                <div class="flex flex-row w-screen justify-center">
                    <input class="border lg:border-0 border-blue-400 border-r-0 text-base rounded-l-lg placeholder-gray-600 shadow-lg h-12 px-5 w-10/12 sm:w-9/12 md:w-7/12 lg:w-1/2 xl:w-2/5 focus:outline-none" placeholder="Search the web without being tracked"></input>
                    <div class="flex border lg:border-0 border-blue-400 border-l-0 bg-white rounded-r-lg shadow-lg h-12 px-5 justify-center">
                        <i class="flex text-gray-600 fa fa-search my-auto"></i>
                    </div>
                </div>
                <h2 class="text-3xl text-center text-white font-extrabold mx-2 mt-8 mb-3">Helping those who change the world.</h2>
                <span class="text-gray-100 text-sm sm:text-base tracking-wide font-semibold text-center w-3/4 mb-6 sm:mb-3">In place of ads, Wuffee promotes non-profits that are driving social change.</span>
                <div class="flex flex-col md:flex-row">
                    <div class="flex mb-5 md:mb-0">
                        <div class="flex justify-center w-4 h-4 rounded-full bg-blue-500 shadow-sm mr-2 my-auto">
                            <i class="fa fa-check text-white text-xs my-auto"></i>
                        </div>
                        <div class="text-gray-100 tracking-tight font-bold my-auto">
                            Private Search
                        </div>
                    </div>
                    <div class="flex ml-0 mb-5 md:ml-5 md:mb-0">
                        <div class="flex justify-center w-4 h-4 rounded-full bg-blue-500 shadow-sm mr-2 my-auto">
                            <i class="fa fa-check text-white text-xs my-auto"></i>
                        </div>
                        <div class="text-gray-100 tracking-tight font-bold my-auto">
                            Socially Aware
                        </div>
                    </div>
                    <div class="flex ml-0 md:ml-5">
                        <div class="flex justify-center w-4 h-4 rounded-full bg-blue-500 shadow-sm mr-2 my-auto">
                            <i class="fa fa-check text-white text-xs my-auto"></i>
                        </div>
                        <div class="text-gray-100 tracking-tight font-bold my-auto">
                            Sustainable
                        </div>
                    </div>
                </div>
                <button class="bg-blue-500 hover:bg-blue-600 text-xs uppercase tracking-wider font-bold text-white rounded-lg px-6 py-3 shadow-md hover:shadow-lg mt-8 transition duration-200 focus:outline-none">
                    <a href="https://help.wuffee.com">
                        Add Wuffee to Chrome
                    </a>
                </button>
            </section>
            <section class="cards flex flex-col lg:flex-row justify-center items-center" style="margin-top: 11rem;">
                <div class="flex flex-col items-center bg-white shadow-lg py-10 mb-6" style="border-radius: 1rem; width: 20rem;">
                    <img src="https://duckduckgo.com/assets/home/landing/icons/search.svg" class="h-16" />
                    <h2 class="text-lg font-extrabold mt-8" style="color: #353748;">Socially Aware</h2>
                    <p class="text-gray-700 text-sm text-center leading-relaxed mt-1 mx-6">Search privately with our app or extension, or search directly at our website <span href="#" class="font-semibold text-indigo-500">wuffee.com</span>.</p>
                </div>
                <div class="flex flex-col items-center bg-white shadow-lg py-10 mb-6 mx-6" style="border-radius: 1rem; width: 20rem;">
                    <img src="https://duckduckgo.com/assets/add-to-browser/cppm/mobile.svg" class="h-16" />
                    <h2 class="text-lg font-extrabold mt-8" style="color: #353748;">Unbiased Results</h2>
                    <p class="text-gray-700 text-sm text-center leading-relaxed mt-1 mx-6">Our private browser for mobile comes equipped with our search engine, available on <span href="#" class="font-semibold text-indigo-500">iOS & Android</span>.</p>
                </div>
                <div class="flex flex-col items-center bg-white shadow-lg py-10 mb-6" style="border-radius: 1rem; width: 20rem;">
                    <img src="https://duckduckgo.com/assets/add-to-browser/cppm/laptop.svg" class="h-16" />
                    <h2 class="text-lg font-extrabold mt-8" style="color: #353748;">Privacy by Design</h2>
                    <p class="text-gray-700 text-sm text-center leading-relaxed mt-1 mx-6">Browse as usual, and we'll take care of the rest. We bundled tracker blocker into our <span href="#" class="font-semibold text-indigo-500">Search Engine</span>.</p>
                </div>
            </section>
            <section class="flex flex-col justify-center items-center mt-24 mb-32">
                <div class="text-center">
                    <h1 class="text-4xl text-center leading-tight font-extrabold" style="color: #353748;">We stand for <br></br> a better internet.</h1>
                    <p class="text-gray-600 text-xl text-center mt-5">In place of ads, Wuffee promotes organizations and <br></br>non-profits who are driving social change.</p>
                    <button class="bg-blue-500 hover:bg-blue-600 text-sm uppercase tracking-wide font-bold text-white rounded-lg px-6 py-3 shadow hover:shadow-lg mt-8 transition duration-200 focus:outline-none">
                        <a href="https://help.wuffee.com">
                            Add Wuffee to Chrome
                        </a>
                    </button>
                </div>
            </section>
            <section class="py-12">
                
            </section>
        </div>
    ),
    oncreate: () => {
        document.getElementsByTagName("input")[0].addEventListener("keydown", function(event) {
            if (event.key == "Enter" && event.target.value.length > 0) {
                m.route.set("/search", {
                    q: event.target.value
                })
            }
        })
    }
}

export default Home;
